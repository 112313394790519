footer .menu ul {
    list-style: none;
}

footer .menu ul li {
    display: inline-block;
}

footer .menu ul li a {
    color: #0A0757 !important;
}

footer .menu ul li a.social {
    font-size: 18px;
}

footer .menu ul li a .fa-globe {
    font-size: 16px;
}

@media screen and (max-width: 780px) {
    footer {
        text-align: center;
    }
}