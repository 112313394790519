@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

body {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    color: #62646a;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .font-pencil {
    font-family: 'Dancing Script', cursive;
  }