.bg-grey {
    background: #f6f6f6;
}

.bg-dark-blue {
    background: #0A0757 !important;
}

.cursor {
    cursor: pointer !important;
}

.font-normal {
    font-weight: 400 !important;
}

.header.navbar {
    height: 77px;
    z-index: 10;
    background: transparent;
    transition: all .6s ease;
}

.header.navbar .navbar-toggler {
    border: none;
    background: trasparent;
    outline: none;
}

.header.navbar.scrool {
    background: #ffffff !important;
    transition: all .6s ease;
    -webkit-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
}

.header.navbar .logo {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
}

.header.navbar.scrool .logo {
    color: #62646a;
}

.header.navbar .logo .red {
    color: #FF0101;
}

.header .nav-item .nav-link,
.header .nav-item .nav-link:hover,
.header .nav-item .nav-link:focus,
.header .nav-item .nav-link:active {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff !important;
    padding: 0 15px !important;
}

.header.scrool .nav-item .nav-link {
    color: #62646a !important;
}

.header form .join {
    border: 1px solid #ffffff;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 15px;
}

.header.scrool form .join {
    border: 1px solid #008000;
    color: #008000;
}

.header.scrool form .join:hover,
.header.scrool form .join:focus,
.header.scrool form .join:active {
    background: #008000;
    color: #ffffff !important;
}

.header form .join:hover,
.header form .join:active,
.header form .join:focus {
    border: 1px solid #ffffff;
    background: #ffffff;
    color: #62646a !important;
}

@media(max-width: 780px) {
    .header.navbar .navbar-collapse {
        background: #ffffff;
        position: absolute;
        top: 77px;
        right: 0;
        left: 0;
        min-height: 100%;
        border-top: 1px solid #f9f9f9;
        padding: 20px;
    }

    .header .nav-item .nav-link {
        color: #62646a !important;
        display: block;
        text-align: center;
        padding: 10px !important;
    }    

    .header.navbar .navbar-toggler {
        position: absolute;
        right: 10px;
        vertical-align: middle;
    }

    .header form .join {
        border: 1px solid #008000;
        color: #008000;
        display: block;
        width: 100%;
        margin-top: 10px;
    }
}

.hero {
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.5)),
        url('https://emc.xyz/static/media/banner-v2.c3f1c439.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #ffffff;
    margin-top: -77px;
}

.hero .title {
    font-size: 42px;
}

.hero form .input-group-text {
    background: #ffffff !important;
    border: 0 !important;
    box-shadow: none;
    font-size: 14px;
}

.hero form .form-control {
    height: 50px;
    border: none;
    box-shadow: none;
}

.hero form .btn {
    border: 0;
}

.hero .label .btn-primary {
    border: 1px solid #ffffff;
    background: transparent;
    font-weight: 300;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 22px;
    margin-right: 7px;
}

.hero .label .btn-primary:hover,
.hero .label .btn-primary:active,
.hero .label .btn-primary:focus {
    border: 1px solid #ffffff;
    background: #ffffff;
    color: #62646a !important;
}

.popular .see-more {
    font-size: 14px;
}

.popular .card {
    border: none;
    transition: all .4s ease;
    cursor: pointer;
}

.popular .card-img-overlay {
    background: rgba(0, 0, 0, 0.3);
    transition: all .4s ease-in;
}

.popular .card-img-overlay:hover {
    background: rgba(0, 0, 0, 0.2);
}

.our-story {
    background: #F0FDF6;
}

.our-story .content .content-single i {
    padding: 5px;
    border: 1px solid #62646a;
    border-radius: 50%;
    font-size: 12px;
}

.our-story .content .content-single .top {
    font-size:18px;
    font-weight: 700;
}

.our-story .content .content-single .bottom  {
    font-size: 18px;
    font-weight: 300;
}

.banner .banner-img {
    background: #4A73E7 url('https://fiverr-res.cloudinary.com/q_auto,f_auto,w_1160,dpr_2.0/v1/attachments/generic_asset/asset/b49b1963f5f9008f5ff88bd449ec18f7-1608035772453/logo-maker-banner-wide-desktop-1352-2x.png') no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 8px;
}

.banner .btn {
    background: #ffffff;
    border: 2px solid #ffffff;
    color: #4A73E7;
    font-weight: 500;
    padding: 10px 25px;
}

.banner .btn:hover {
    background: transparent;
    border: 2px solid #ffffff;
}

@media screen and (max-width: 780px) {
    .banner .banner-img {
        background: #4A73E7;
    }
}

footer .logo {
    font-size: 32px;
    font-weight: 700;
    color: #62646a;
}

footer .logo .red {
    color: #FF0101;
}

.partner {
    background: #FAFAFA;
}

.partner img {
    width: 100px;
    height: 80px;
    display: inline;
    object-fit: contain;
}

.category p {
    text-transform: uppercase;
}

.accordion .card {
    background: #ffffff !important;
    border: none;
    padding: 0 !important;
}

.accordion .card .card-header {
    padding: 0 !important;
    background: #ffffff !important;
}

.accordion .card .card-header .btn,
.accordion .card .card-header .btn:hover,
.accordion .card .card-header .btn:active,
.accordion .card .card-header .btn:focus {
    color: #62646a !important;
    padding: 15px 0 !important;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
}

.accordion .card .card-body {
    background: #ffffff;
    border: #ffffff !important;
    border-left: 3px solid #4A73E7 !important;
}

.busiz .title {
    font-size: 62px;
}

.busiz .content {
    font-size: 18px;
}

.busiz .arrow {
    background: transparent url("https://fiverr-res.cloudinary.com/npm-assets/@fiverr/business_onboarding_perseus/apps/hero-arrow.2fc0e09.svg") no-repeat;
    background-size: 30px 54px;
    width: 30px;
    height: 54px;
    position: absolute;
    bottom: 10px;
    animation: arrow-bounce 2s infinite;
}

@keyframes arrow-bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}